import React, { useState, useEffect, useRef } from "react";

const DynamicHtmlPages = () => {
  const [pages, setPages] = useState([{ id: 1, content: "" }]); // Array of pages with HTML content
  const pageRefs = useRef([]); // References to each page

  const longHtmlContent = `
    <h1>Title</h1>
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
    <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
    <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
    <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
    <p>More content that will likely overflow the page. This paragraph is deliberately long to test splitting across multiple pages.</p>
   
    
  `;

  const addHtmlContent = () => {
    const remainingHtml = document.createElement("div");
    remainingHtml.innerHTML = longHtmlContent;

    while (remainingHtml.children.length > 0) {
      const lastPageIndex = pages.length - 1;
      const lastPageRef = pageRefs.current[lastPageIndex];
      const child = remainingHtml.children[0];

      lastPageRef.appendChild(child);

      if (lastPageRef.scrollHeight > lastPageRef.clientHeight) {
        // Remove the last added child to prevent overflow
        lastPageRef.removeChild(child);

        // Create a new page and add the child to the new page
        setPages((prevPages) => [
          ...prevPages,
          { id: prevPages.length + 1, content: "" },
        ]);
        break;
      } else {
        // Update the content in the current page state
        setPages((prevPages) => {
          const updatedPages = [...prevPages];
          updatedPages[lastPageIndex].content += child.outerHTML;
          return updatedPages;
        });

        // Remove the child from the remainingHtml
          remainingHtml.removeChild(child);
      }
    }
  };

  useEffect(() => {
    addHtmlContent(); // Add initial HTML content
  }, []);

  useEffect(() => {
    // Check overflow whenever a new page is added
    const lastPageIndex = pages.length - 1;
    const lastPageRef = pageRefs.current[lastPageIndex];

    if (lastPageRef && lastPageRef.scrollHeight > lastPageRef.clientHeight) {
      setPages((prevPages) => [
        ...prevPages,
        { id: prevPages.length + 1, content: "" },
      ]);
    }
  }, [pages]);

  return (
    <div className="container">
      {pages.map((page, index) => (
        <div
          key={page.id}
          className="page"
          style={{
            width: "794px",
            height: "1123px",
            border: "1px solid black",
            margin: "10px auto",
            overflow: "hidden",
            position: "relative",
          }}
          ref={(el) => (pageRefs.current[index] = el)}
        >

        </div>
      ))}
    </div>
  );
};

export default DynamicHtmlPages;
