import React, { lazy } from "react";
import { Link, useNavigate } from "react-router-dom";
const Footer = lazy(() => import("../../Components/FooterComponents/Footer"));
const Navbar = lazy(() => import("../../Components/NavBarComponents/Navbar"));

function ResumesPage() {
  const navigator = useNavigate();
  return (
    <div>
      <Navbar />
      <div className="mt-32">
        <div className="flex items-center w-full ml-5 mb-2 mt-4">
          <div className="bg-orange-500 w-1 h-5 mr-3"></div>
          <h1 className="text-medium font-bold" >Choisissez un template pour votre CV</h1>
        </div>
        <div className="grid grid-cols-1 gap-6 p-8 sm:grid-cols-2 lg:grid-cols-4">
          <Link to={`/create-resume-page?template=Template1`}>
            <div className="p-6 bg-white border-orange relative  duration-700 ease-in-out hover:scale-110 hover:transition-all hover:brightness-75 hover:brightness-75">
              <div className="flex flex-col h-full text-black  left-0 top-0">
                <div className="flex-1 mb-4">
                  <img
                    src={window.location.origin + "/Templates/tmp1.png"}
                  />
                </div>
              </div>
              <div className="w-full h-10 bg-orange-500 flex items-center content-center absolute bottom-0 left-0">
                <p className="w-full text-center font-bold">The Wave Of Confidence</p>
              </div>
            </div>
          </Link>
          <Link to={`/create-resume-page?template=Template2`}>
            <div className="p-6 bg-white border-orange relative  duration-700 ease-in-out hover:scale-110 hover:transition-all hover:brightness-75">
              <div className="flex flex-col h-full text-black  left-0 top-0">
                <div className="flex-1 mb-4">
                  <img
                    src={window.location.origin + "/Templates/tmp2.png"}
                  />
                </div>
              </div>
              <div className="w-full h-10 bg-orange-500 flex items-center content-center absolute bottom-0 left-0">
                <p className="w-full text-center font-bold">Candy Smell</p>
              </div>
            </div>
          </Link>
          <Link to={`/create-resume-page?template=Template3`}>
            <div className="p-6 bg-white border-orange relative duration-700 ease-in-out hover:scale-110 hover:transition-all hover:brightness-75">
              <div className="flex flex-col h-full text-black  left-0 top-0">
                <div className="flex-1 mb-4">
                  <img
                    src={window.location.origin + "/Templates/tmp3.png"}
                  />
                </div>
              </div>
              <div className="w-full h-10 bg-orange-500 flex items-center content-center absolute bottom-0 left-0">
                <p className="w-full text-center font-bold">Water Whale
                </p>
              </div>
            </div>
          </Link>
          <Link to={`/create-resume-page?template=Template4`}>
            <div className="p-6 bg-white border-orange relative duration-700 ease-in-out hover:scale-110 hover:transition-all hover:brightness-75">
              <div className="flex flex-col h-full text-black  left-0 top-0">
                <div className="flex-1 mb-4">
                  <img
                    src={window.location.origin + "/Templates/tmp4.png"}
                  />
                </div>
              </div>
              <div className="w-full h-10 bg-orange-500 flex items-center content-center absolute bottom-0 left-0">
                <p className="w-full text-center font-bold">Solar Eclipse</p>
              </div>
            </div>
          </Link>
          <Link to={`/create-resume-page?template=Template7`}>
            <div className="p-6 bg-white border-orange relative duration-700 ease-in-out hover:scale-110 hover:transition-all hover:brightness-75">
              <div className="flex flex-col h-full text-black  left-0 top-0">
                <div className="flex-1 mb-4">
                  <img
                    src={window.location.origin + "/Templates/tmp7.png"}
                  />
                </div>
              </div>
              <div className="w-full h-10 bg-orange-500 flex items-center content-center absolute bottom-0 left-0">
                <p className="w-full text-center font-bold">Orange Is Here</p>
              </div>
            </div>
          </Link>
          <Link to={`/create-resume-page?template=Template8`}>
            <div className="p-6 bg-white border-orange relative duration-700 ease-in-out hover:scale-110 hover:transition-all hover:brightness-75">
              <div className="flex flex-col h-full text-black  left-0 top-0">
                <div className="flex-1 mb-4">
                  <img
                    src={window.location.origin + "/Templates/tmp8.png"}
                  />
                </div>
              </div>
              <div className="w-full h-10 bg-orange-500 flex items-center content-center absolute bottom-0 left-0">
                <p className="w-full text-center font-bold">Mother Nature</p>
              </div>
            </div>
          </Link>
          <Link to={`/create-resume-page?template=Template5`}>
            <div className="p-6 bg-white border-orange relative duration-700 ease-in-out hover:scale-110 hover:transition-all hover:brightness-75">
              <div className="flex flex-col h-full text-black  left-0 top-0">
                <div className="flex-1 mb-4">
                  <img
                    src={window.location.origin + "/Templates/tmp5.png"}
                  />
                </div>
              </div>
              <div className="w-full h-10 bg-orange-500 flex items-center content-center absolute bottom-0 left-0">
                <p className="w-full text-center font-bold">School Uniform</p>
              </div>
            </div>
          </Link>
          <Link to={`/create-resume-page?template=Template6`}>
            <div className="p-6 bg-white border-orange relative duration-700 ease-in-out hover:scale-110 hover:transition-all hover:brightness-75">
              <div className="flex flex-col h-full text-black  left-0 top-0">
                <div className="flex-1 mb-4">
                  <img
                    src={window.location.origin + "/Templates/tmp6.png"}
                  />
                </div>
              </div>
              <div className="w-full h-10 bg-orange-500 flex items-center content-center absolute bottom-0 left-0">
                <p className="w-full text-center font-bold">Dawn Light...</p>
              </div>
            </div>
          </Link>
         

        </div>
      </div>
      <Footer />

    </div>
  );
}

export default ResumesPage;
